import Api from "@/api/Api";
import ActivityIndicator from "@/components/activity/ActivityIndicator";
import Input from "@/components/form/Input";
import SubmitOrCancelButtons from "@/components/form/SubmitOrCancelButtons";
import TextEditor from "@/components/form/TextEditor";
import { useBreadcrumb } from "@/contexts/providers/BreadcrumbContext";
import { flashSuccessMessage } from "@/utils/alert_utils";
import Modeler from "@/utils/modeler/modeler";
import { route } from "@/utils/route_utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SettingsTitle from "../SettingsTitle";
const ObituaryEdit = () => {
  const breadcrumb = useBreadcrumb();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { slug } = useParams();
  const [changed, setChanged] = useState(false);
  const [project, setProject] = useState();
  useEffect(() => {
    const fetchProject = async () => {
      var _a;
      const { data } = await Api.utility.get(route("projectsUpdate", { slug }));
      const modeledProject = new Modeler(data).build();
      setProject(modeledProject);
      breadcrumb.setCrumbs([
        {
          label: `${(_a = modeledProject.prefix) != null ? _a : ""} ${modeledProject.full_name}`,
          href: route("project", { slug })
        },
        {
          label: "Admin Settings"
        },
        {
          label: "Obituary"
        }
      ]);
      mixpanel.track("settings_viewed", {
        kind: "project",
        project_id: modeledProject.id,
        type: "obituary"
      });
    };
    fetchProject();
  }, []);
  const handleUpdateObituary = async (e) => {
    e.preventDefault();
    await Api.utility.patch(route("projectsUpdate", { slug }), { project });
    flashSuccessMessage("Obituary successfully updated");
    history.push(route("project", { slug }));
  };
  const mutation = useMutation({
    mutationFn: handleUpdateObituary,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["project", { slug }]
      });
      flashSuccessMessage("Obituary successfully updated");
      history.push(route("project", { slug }));
    }
  });
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(SettingsTitle, null, "Obituary"), project && /* @__PURE__ */ React.createElement("form", { method: "patch", onSubmit: mutation.mutate }, /* @__PURE__ */ React.createElement("p", { className: "mb-4" }, "Newspaper obituaries often stay online for just 1 year, so we recommend you copy/paste the text of ", project.preferred_name, "'s obituary to display here for as long as you'd like. This is a great place to capture their full story and who they were, not just what they did."), /* @__PURE__ */ React.createElement(
    TextEditor,
    {
      onChange: (obituary, _, source) => {
        setChanged(source === "user");
        setProject({ ...project, obituary });
      },
      value: project.obituary
    }
  ), /* @__PURE__ */ React.createElement("p", { className: "my-4" }, `If you'd prefer to link to an obituary on another site, you can copy/paste that URL here and the "Obituary" button on this LifeWeb will direct visitors to that site in a new window. If you save a link, it will override any text you have saved in the space above.`), /* @__PURE__ */ React.createElement("label", { htmlFor: "obituary_url" }, "Link to an external obituary"), /* @__PURE__ */ React.createElement(
    Input,
    {
      id: "obituary_url",
      onChange: (url) => {
        setChanged(true);
        setProject({ ...project, obituary_url: url });
      },
      placeholder: "https://www.example.com/",
      value: project.obituary_url || ""
    }
  ), /* @__PURE__ */ React.createElement(
    SubmitOrCancelButtons,
    {
      disabled: !changed,
      onCancel: () => history.push(route("project", {
        slug
      }))
    }
  )), !project && /* @__PURE__ */ React.createElement(ActivityIndicator, null));
};
export default ObituaryEdit;
