import Api from "@/api/Api";
import ButtonLink from "@/components/button/ButtonLink";
import IconButton from "@/components/button/IconButton";
import Icon from "@/components/icon/Icon";
import CloseButton from "@/components/modal/CloseButton";
import Modal from "@/components/modal/Modal";
import { H2 } from "@/components/typography";
import useSortedCategories from "@/helpers/hooks/use_sorted_categories";
import { flashErrorMessage } from "@/utils/alert_utils";
import Modeler from "@/utils/modeler/modeler";
import { route } from "@/utils/route_utils";
import { isLightBackground } from "@/utils/text_utils";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
const defaultCoverImageSrc = require("@/assets/images/project_default.jpg");
const FeaturedCategories = ({ categories }) => {
  const {
    name,
    featured_color: featuredColor,
    featured_icon_url: featuredIconUrl
  } = categories[0].parent;
  const { groupName } = useParams();
  const trackCategoryLinkClick = () => {
    mixpanel.track("groupcta_clicked", {
      kind: "group",
      group_name: groupName,
      type: "distinction"
    });
  };
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "border w-full sm:w-auto sm:min-w-[50%] my-4",
      style: { borderColor: featuredColor }
    },
    /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classnames(
          "flex font-semibold gap-3 items-center justify-center p-3 uppercase",
          {
            "text-white": !isLightBackground(featuredColor)
          }
        ),
        style: { backgroundColor: featuredColor }
      },
      featuredIconUrl && /* @__PURE__ */ React.createElement("img", { className: "h-6 w-6", src: featuredIconUrl }),
      name
    ),
    /* @__PURE__ */ React.createElement("div", { className: "flex flex-col font-semibold items-center p-2" }, categories.map((category) => /* @__PURE__ */ React.createElement("div", { key: category.id, className: "text-black" }, category.link ? /* @__PURE__ */ React.createElement(
      "a",
      {
        className: "flex items-center underline",
        href: category.link,
        onClick: trackCategoryLinkClick,
        target: "_blank"
      },
      category.name,
      /* @__PURE__ */ React.createElement("span", { className: "block ml-2 -mt-1 w-3" }, /* @__PURE__ */ React.createElement(Icon, { icon: "openInNew" }))
    ) : category.name)))
  );
};
const ProjectDetailModal = ({ group }) => {
  const [project, setProject] = useState(null);
  const history = useHistory();
  const { groupName, id, memberName } = useParams();
  const sortedCategories = useSortedCategories(project == null ? void 0 : project.group_categories, {
    sort: "featured"
  });
  useEffect(() => {
    if (!project)
      return;
    mixpanel.track("member_viewed", {
      kind: "group",
      group_name: group.slug,
      project_id: project.project.id
    });
  }, [project]);
  const onClose = () => history.push(`/groups/${groupName}`);
  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await Api.utility.get(
          route("groupProject", { group_id: group.id, id })
        );
        setProject(new Modeler(response.data).build());
      } catch (err) {
        flashErrorMessage(`${group.member_name} not found`);
      }
    };
    fetchProject();
  }, [id]);
  const trackNavigateToProject = (source) => {
    mixpanel.track("project_viewed_from_group", {
      kind: "group",
      group_name: groupName,
      project_id: project.project.id,
      source,
      type: "button"
    });
  };
  if (!project)
    return;
  return /* @__PURE__ */ React.createElement(Modal, { size: "medium", isVisible: !!project, onClose }, /* @__PURE__ */ React.createElement("div", { className: "grid grid-cols-2 mb-2" }, /* @__PURE__ */ React.createElement("div", null, group.current_user_role === "admin" && /* @__PURE__ */ React.createElement(
    IconButton,
    {
      icon: "pencil",
      onClick: () => history.push(
        route("groupProjectEdit", {
          id,
          slug: groupName,
          member_name: memberName
        })
      ),
      size: "small",
      text: "Edit Filters"
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "flex justify-end" }, /* @__PURE__ */ React.createElement(CloseButton, { onClose }))), /* @__PURE__ */ React.createElement("div", { className: "flex flex-col justify-center items-center text-center" }, /* @__PURE__ */ React.createElement(
    "a",
    {
      className: "block mx-auto",
      href: route("project", { slug: project.project.slug }),
      onClick: () => trackNavigateToProject("cover_image"),
      target: "_blank"
    },
    /* @__PURE__ */ React.createElement(
      "img",
      {
        className: "max-w-sm my-2 rounded w-full",
        src: project.project.cover_image_url || group.project_image_url || defaultCoverImageSrc
      }
    )
  ), /* @__PURE__ */ React.createElement(H2, null, /* @__PURE__ */ React.createElement(
    "a",
    {
      className: "underline",
      href: route("project", { slug: project.project.slug }),
      onClick: () => trackNavigateToProject("project_name"),
      target: "_blank"
    },
    project.project.prefix,
    " ",
    project.project.full_name
  )), Object.entries(sortedCategories).map(
    ([key, values]) => values.find(({ parent }) => parent == null ? void 0 : parent.featured) ? /* @__PURE__ */ React.createElement(FeaturedCategories, { key, categories: values }) : /* @__PURE__ */ React.createElement("p", { key }, key !== "Category" && /* @__PURE__ */ React.createElement("span", { className: "font-semibold" }, key, ":"), " ", values.map(({ name }) => name).join(", "))
  ), /* @__PURE__ */ React.createElement("div", { className: "flex flex-col md:flex-row items-center justify-center gap-4 my-6 w-full" }, project.project.obituary && !project.project.obituary_url && /* @__PURE__ */ React.createElement(
    ButtonLink,
    {
      className: "bg-lifeweb-gold w-full md:w-1/3",
      href: route("projectObituary", { slug: project.project.slug }),
      onClick: () => trackNavigateToProject("view_obituary")
    },
    "View Obituary"
  ), project.project.obituary_url && /* @__PURE__ */ React.createElement(
    ButtonLink,
    {
      className: "bg-lifeweb-gold flex items-center justify-center w-full md:w-1/3",
      href: project.project.obituary_url,
      onClick: () => {
        mixpanel.track("obituary_external", {
          kind: "project",
          project_id: project.id
        });
      }
    },
    /* @__PURE__ */ React.createElement("span", null, "View Obituary"),
    /* @__PURE__ */ React.createElement("span", { className: "block ml-2 -mt-1 w-4" }, /* @__PURE__ */ React.createElement(Icon, { icon: "openInNew" }))
  ), project.project.memories_count > 0 && /* @__PURE__ */ React.createElement(
    ButtonLink,
    {
      className: "bg-lifeweb-gold px-2 w-full md:w-1/3",
      href: route("project", { slug: project.project.slug }),
      onClick: () => trackNavigateToProject("view_memories")
    },
    "View Memories (",
    project.project.memories_count,
    ")"
  ), /* @__PURE__ */ React.createElement(
    ButtonLink,
    {
      className: "bg-lifeweb-gold w-full md:w-1/3",
      href: route("memoriesNew", { slug: project.project.slug }),
      onClick: () => trackNavigateToProject("post_memory")
    },
    "Post a Memory"
  ))));
};
export default ProjectDetailModal;
