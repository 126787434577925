import Api from "@/api/Api";
import Button from "@/components/button/Button";
import facebookAuthCallback from "@/helpers/facebook_auth_callback";
import googleAuthCallback from "@/helpers/google_auth_callback";
import { route } from "@/utils/route_utils";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AnotherSocialAccountLinkModal from "../modal/AnotherSocialAccountLinkModal";
import CreateAccountWithSocialModal from "../modal/CreateAccountWithSocialModal";
import FirstSocialAccountLinkModal from "../modal/FirstSocialAccountLinkModal";
const SocialLoginButtons = (props) => {
  const {
    generatedFormAuthenticityToken,
    loginPage,
    redirectPath: redirectPathQueryString,
    googleApiAppId
  } = props;
  const { t } = useTranslation();
  const [facebookLoading, setFacebookLoading] = useState(false);
  const [firstSocialModalVisible, setFirstSocialModalVisible] = useState(false);
  const [
    createAccountWithSocialModalVisible,
    setCreateAccountWithSocialModalVisible
  ] = useState(false);
  const [anotherSocialModalVisible, setAnotherSocialModalVisible] = useState(false);
  const [authInformation, setAuthInformation] = useState({});
  const [existingSocialProvider, setExistingSocialProvider] = useState("");
  const humanizeProvidersName = (authInformation2) => {
    if (Object.keys(authInformation2).length === 0)
      return "";
    const provider = authInformation2.provider;
    return providerName(provider);
  };
  const providerName = (providerName2) => {
    switch (providerName2) {
      case "facebook":
        return "Facebook";
      case "google_oauth2":
        return "Google";
      default:
        return "";
    }
  };
  const closeAnotherSocialModal = (e) => {
    e.preventDefault();
    setAnotherSocialModalVisible(false);
  };
  const closeCreateAccountWithSocialModal = (e) => {
    e.preventDefault();
    setCreateAccountWithSocialModalVisible(false);
  };
  const closeFirstSocialModal = (e) => {
    e.preventDefault();
    setFirstSocialModalVisible(false);
  };
  const authConfirm = async (e) => {
    e.preventDefault();
    try {
      const socialUserCreationConfUrl = route(
        "socialUserCreationConfirmation"
      );
      const body = {
        provider_auth_information: authInformation,
        redirect_path: redirectPathQueryString
      };
      const response = await Api.utility.post(socialUserCreationConfUrl, body);
      window.location.href = response.data.redirect_path || "/";
    } catch (e2) {
      console.log(e2);
    }
  };
  const authAction = (authActionScenarios, responseCallback) => {
    var _a;
    setFacebookLoading(false);
    switch (authActionScenarios) {
      case "no_user":
        setCreateAccountWithSocialModalVisible(true);
        break;
      case "social_account_exists":
        const redirectPath = ((_a = responseCallback == null ? void 0 : responseCallback.data) == null ? void 0 : _a.redirect_path) || "/";
        window.location.href = redirectPathQueryString || redirectPath;
        break;
      case "one_social_link":
        setFirstSocialModalVisible(true);
        break;
      case "another_social_link":
        setAnotherSocialModalVisible(true);
        break;
      default:
        console.log("Unknown");
    }
  };
  const googleAuthHandler = (e) => {
    e.preventDefault();
    const googleClient = window.google.accounts.oauth2.initCodeClient({
      client_id: googleApiAppId,
      scope: "email profile",
      ux_mode: "popup",
      callback: async (response) => {
        var _a;
        try {
          const responseCallback = await googleAuthCallback(
            response,
            generatedFormAuthenticityToken,
            loginPage,
            false
          );
          const redirectPath = responseCallback.data.redirect_path;
          if (loginPage) {
            const authInformation2 = responseCallback.data.provider_auth_information;
            const existingSocialProvider2 = (_a = responseCallback.data) == null ? void 0 : _a.existing_provider;
            setAuthInformation(authInformation2);
            setExistingSocialProvider(existingSocialProvider2);
            authAction(
              responseCallback.data.auth_action_scenario,
              responseCallback
            );
          } else {
            window.location.href = redirectPath || "/";
          }
        } catch (err) {
        }
      }
    });
    googleClient.requestCode();
  };
  const facebookAuthHandler = async (e) => {
    setFacebookLoading(true);
    e.preventDefault();
    window.FB.login(
      async (response) => {
        var _a;
        if (response.status == "connected") {
          try {
            const responseCallback = await facebookAuthCallback(
              response,
              loginPage,
              false
            );
            const redirectPath = responseCallback.data.redirect_path;
            if (loginPage) {
              const authInformation2 = responseCallback.data.provider_auth_information;
              const existingSocialProvider2 = (_a = responseCallback.data) == null ? void 0 : _a.existing_provider;
              setAuthInformation(authInformation2);
              setExistingSocialProvider(existingSocialProvider2);
              authAction(
                responseCallback.data.auth_action_scenario,
                responseCallback
              );
            } else {
              setFacebookLoading(false);
              window.location.href = redirectPath || "/";
            }
          } catch (err) {
            setFacebookLoading(false);
          }
        } else {
          setFacebookLoading(false);
        }
      },
      { scope: "public_profile, email" }
    );
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "flex flex-col gap-6 items-center mx-auto md:max-w-sm" }, /* @__PURE__ */ React.createElement(Button, { isGoogleBtn: true, onClick: googleAuthHandler, loading: false }, /* @__PURE__ */ React.createElement(
    "img",
    {
      alt: "Google logo",
      src: require("@/assets/images/icons/google_icon_white_round.svg"),
      className: "social-media-icons"
    }
  ), t("views.memories.usersNew.continueWithGoogleButton")), /* @__PURE__ */ React.createElement(
    Button,
    {
      isFacebookBtn: true,
      onClick: facebookAuthHandler,
      loading: facebookLoading
    },
    /* @__PURE__ */ React.createElement(
      "img",
      {
        alt: "Facebook logo",
        src: require("@/assets/images/icons/facebook_icon_white_round.svg"),
        className: "social-media-icons"
      }
    ),
    t("views.memories.usersNew.continueWithFacebookButton")
  ), /* @__PURE__ */ React.createElement("div", { className: "max-w-xs w-full" }, /* @__PURE__ */ React.createElement("div", { className: "divider my-4" }, "OR"))), /* @__PURE__ */ React.createElement(
    FirstSocialAccountLinkModal,
    {
      visible: firstSocialModalVisible,
      onConfirm: authConfirm,
      onCancel: closeFirstSocialModal,
      newSocialProvider: humanizeProvidersName(authInformation)
    }
  ), /* @__PURE__ */ React.createElement(
    CreateAccountWithSocialModal,
    {
      visible: createAccountWithSocialModalVisible,
      onCancel: closeCreateAccountWithSocialModal,
      onConfirm: authConfirm
    }
  ), /* @__PURE__ */ React.createElement(
    AnotherSocialAccountLinkModal,
    {
      visible: anotherSocialModalVisible,
      onCancel: closeAnotherSocialModal,
      onConfirm: authConfirm,
      newSocialProvider: humanizeProvidersName(authInformation),
      existingSocialProvider: providerName(existingSocialProvider)
    }
  ));
};
export default SocialLoginButtons;
