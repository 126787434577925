import React, { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Api from "@/api/Api";
import ActionVerification from "@/components/actionVerification/ActionVerification";
import Label from "@/components/form/Label";
import ImageVideoUploader from "@/components/image_video_uploader/ImageVideoUploader";
import Modal from "@/components/modal/Modal";
import MemoryDisplay from "@/contexts/memories/modules/MemoryDisplay";
import { flashMessageFromResponseError } from "@/utils/alert_utils";
import { buildPath } from "@/utils/api_utils";
import Modeler from "@/utils/modeler/modeler";
import Button from "@/components/button/Button";
import ErrorMessage from "@/components/form/ErrorMessage";
import Input from "@/components/form/Input";
import InputGroup from "@/components/form/InputGroup";
import TextEditor from "@/components/form/TextEditor";
import MemoriesAddLifeThreadModal from "@/contexts/memories/components/MemoriesAddLifeThreadModal";
import MemoriesThemes from "@/contexts/memories/components/MemoriesThemes";
import classNames from "classnames";
const MemoriesNew = (props) => {
  const { groups, project, project_themes, user, userData, uploadPath } = props;
  const { t } = useTranslation();
  const projectUser = new Modeler(userData).build();
  const initialFormState = {
    description: "",
    email: "",
    first_name: "",
    last_name: "",
    relationship_description: "",
    relationship_types: [],
    text: "",
    themes: [],
    video_thumbnail_url: "",
    video_url: ""
  };
  const [form, setForm] = useState(initialFormState);
  const formRef = useRef(form);
  const [isSubmittingNewMemory, setIsSumbittingNewMemory] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddLifeThreadModalVisible, setIsAddLifeThreadModalVisible] = useState(false);
  const [newLifeThreadName, setNewLifeThreadName] = useState("");
  const [viewMode, setviewMode] = useState("both");
  const [themes, setThemes] = useState(project_themes);
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [previewImages, setPreviewImages] = useState([]);
  const [onBehalfConfirmation, setOnBehalfConfirmation] = useState(false);
  const uppyUploadRef = useRef(null);
  useEffect(() => {
    formRef.current = form;
  });
  useEffect(() => {
    mixpanel.track("memory_started", {
      kind: "project",
      project_id: project.id
    });
  }, []);
  const closeModal = () => {
    setIsModalVisible(false);
  };
  const formatBody = (images) => {
    const {
      text,
      video_thumbnail_url,
      video_url,
      ...formFields
    } = formRef.current;
    const formattedImages = images.map((i) => ({
      image: i.jsonData
    }));
    const formattedThemes = formRef.current.themes.map(
      (formTheme) => formTheme.id
    );
    const memoryQuoteAttributes = () => {
      return {
        text
      };
    };
    const memoryVideoAttributes = () => {
      return {
        video_url,
        video_thumbnail_url
      };
    };
    return {
      memory: {
        project_id: project.id,
        user_id: user ? user.id : "",
        memory_images_attributes: formattedImages,
        memory_quote_attributes: text ? memoryQuoteAttributes() : null,
        memory_video_attributes: video_url ? memoryVideoAttributes() : null,
        theme_ids: formattedThemes,
        is_on_behalf: formRef.current.first_name.length > 0,
        ...formFields
      }
    };
  };
  const handleOnClick = () => {
    setIsModalVisible(true);
  };
  const submitForm = async (images) => {
    const url = `api/v1/memories`;
    const body = formatBody(images);
    try {
      const response = await Api.utility.post(url, body);
      const data = response.data;
      if (data.redirect_path) {
        const modeledProjectUser = new Modeler(
          data.serialized_project_user
        ).build();
        const memoryType = form.video_url ? "video" : images && images.length > 0 ? "photo" : "text";
        mixpanel.track("memory_created", {
          kind: "project",
          memory_id: data.memory.id,
          project_id: project.id,
          type: memoryType
        });
        window.location = data.redirect_path;
      }
    } catch (err) {
      flashMessageFromResponseError(err);
      setIsSumbittingNewMemory(false);
    }
  };
  const handleSubmitForm = () => {
    setIsSumbittingNewMemory(true);
    if (previewImages && previewImages.length > 0) {
      uppyUploadRef.current();
    } else {
      submitForm([]);
    }
  };
  const createTheme = async (name) => {
    if ((projectUser == null ? void 0 : projectUser.role) === "admin") {
      const url = `api/v1/themes`;
      try {
        const body = {
          theme: {
            name,
            project_id: project.id
          }
        };
        const response = await Api.utility.post(url, body);
        const newTheme = new Modeler(response.data).build();
        refreshProjectThemes(newTheme);
      } catch (err) {
        flashMessageFromResponseError(err);
      }
    } else {
      return null;
    }
  };
  const refreshProjectThemes = async (newTheme) => {
    const url = buildPath("api/v1/themes", {
      project_id: project.id,
      status: "active",
      order: "asc"
    });
    try {
      const response = await Api.utility.get(url);
      const data = new Modeler(response.data).build();
      addTheme(newTheme);
      setThemes(data);
      const inactiveTheme = form.themes.find(
        (theme) => !data.some((formTheme) => theme.id === formTheme.id)
      );
      if (inactiveTheme) {
        removeTheme(inactiveTheme);
      }
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  const updateField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    });
  };
  const addTheme = (theme) => {
    setForm({
      ...form,
      themes: [...form.themes, theme]
    });
  };
  const removeTheme = (theme) => {
    const entry = form.themes.find((formTheme) => formTheme.id === theme.id);
    const filteredThemes = form.themes.filter(
      (formTheme) => formTheme !== entry
    );
    setForm({
      ...form,
      themes: filteredThemes
    });
  };
  const updateThemes = (selectedThemes) => {
    setForm({
      ...form,
      themes: selectedThemes
    });
  };
  const onChangeVideo = (url, thumbnailUrl) => {
    setForm({
      ...form,
      video_url: url,
      video_thumbnail_url: thumbnailUrl
    });
  };
  const onChangeView = (newView) => {
    setviewMode(newView);
  };
  const onChangeDescription = (value, length) => {
    updateField("description", value);
    setDescriptionLength(length);
  };
  const onChangePreviewImages = (images) => {
    setPreviewImages(images);
  };
  const onChangeLifeThread = (lifeThreadName) => {
    setNewLifeThreadName(lifeThreadName);
  };
  const onAddLifeThread = async () => {
    createTheme(newLifeThreadName);
    setIsAddLifeThreadModalVisible(false);
  };
  const onAddLifeThreadModalOnClose = () => {
    setIsAddLifeThreadModalVisible(false);
  };
  const onCustomLifeThreadClicked = () => {
    setNewLifeThreadName("");
    setIsAddLifeThreadModalVisible(true);
  };
  const onBehalfFirstNameValidationMessage = form.first_name.trim() === "" && form.last_name.trim() !== "" ? "Field is required" : null;
  const onBehalfLastNameValidationMessage = form.first_name.trim() !== "" && form.last_name.trim() === "" ? "Field is required" : null;
  const representeeFullNameSet = form.first_name.trim() !== "" && form.last_name.trim() !== "";
  const previewAvailable = () => {
    const available = form.themes.length > 0 && (viewMode !== "both" || descriptionLength > 0) && !onBehalfFirstNameValidationMessage && !onBehalfLastNameValidationMessage;
    if (!representeeFullNameSet) {
      return available;
    }
    return available && onBehalfConfirmation;
  };
  const trackMemoryHighlightLink = () => {
    mixpanel.track("view_memory_highlight_example", {
      kind: "project",
      group_name: groups.map((group) => group.slug).join(","),
      project_id: project.id,
      type: "button"
    });
  };
  const trackMemoryShareLink = () => {
    mixpanel.track("view_inspiration_for_memory", {
      kind: "project",
      group_name: groups.map((group) => group.slug).join(","),
      project_id: project.id,
      type: "button"
    });
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, (projectUser == null ? void 0 : projectUser.role) === "admin" && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-8-tablet memories-new__onbehalf-label-wrapper px-2" }, /* @__PURE__ */ React.createElement(Label, { labelSize: "small", labelNote: "(Optional)", withoutPadding: true }, /* @__PURE__ */ React.createElement("span", null, "Posting as", " ", /* @__PURE__ */ React.createElement("strong", null, user.first_name, " ", user.last_name), ". As an Admin, you can post this memory on behalf of someone else.")))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered memories-new____name-wrapper-no-padding-bottom" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-4-tablet" }, /* @__PURE__ */ React.createElement(
    Input,
    {
      placeholder: t(
        "form.placeholder.memoryOnBehalf.firstName"
      ),
      onChange: (value) => updateField("first_name", value),
      value: form.first_name
    }
  ), onBehalfFirstNameValidationMessage && /* @__PURE__ */ React.createElement(ErrorMessage, { message: onBehalfFirstNameValidationMessage })), /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-4-tablet" }, /* @__PURE__ */ React.createElement(
    Input,
    {
      placeholder: t(
        "form.placeholder.memoryOnBehalf.lastName"
      ),
      onChange: (value) => updateField("last_name", value),
      value: form.last_name
    }
  ), onBehalfLastNameValidationMessage && /* @__PURE__ */ React.createElement(ErrorMessage, { message: onBehalfLastNameValidationMessage }))), representeeFullNameSet ? /* @__PURE__ */ React.createElement(
    ActionVerification,
    {
      verificationMessage: t(
        "form.label.memory.memoryOnBehalfConfirmation"
      ),
      changeVerification: setOnBehalfConfirmation,
      isItalic: true
    }
  ) : null), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-8-tablet memories-new__share" }, /* @__PURE__ */ React.createElement("h2", null, /* @__PURE__ */ React.createElement(Label, { isBold: true, isRequired: true, labelSize: "large" }, t("form.label.memory.memoryShare"))), /* @__PURE__ */ React.createElement(
    ImageVideoUploader,
    {
      viewMode,
      previewImages,
      video_url: form.video_url,
      project_id: project.id,
      video_thumbnail_url: form.video_thumbnail_url,
      onChangePreviewImages,
      onChangeVideo,
      onChangeView,
      afterUpload: submitForm,
      uppyUploadRef,
      uploadPath
    }
  ))), /* @__PURE__ */ React.createElement("div", { className: "memories-new__section-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-8-tablet form-editor" }, /* @__PURE__ */ React.createElement(
    TextEditor,
    {
      onChange: onChangeDescription,
      value: form.description
    }
  ), /* @__PURE__ */ React.createElement("p", { className: "my-4" }, t("form.description.memory.memoryShare"), /* @__PURE__ */ React.createElement(
    "a",
    {
      className: "link ml-1",
      href: process.env.external_url["memory_prompts"],
      onClick: trackMemoryShareLink,
      target: "_blank"
    },
    t("form.description.memory.memoryShareLink")
  ))))), /* @__PURE__ */ React.createElement("div", { className: "memories-new__section-wrapper" }, /* @__PURE__ */ React.createElement(
    MemoriesThemes,
    {
      createTheme,
      themes: form.themes,
      onAddTheme: addTheme,
      onRemoveTheme: removeTheme,
      onAddNewLifeThread: (projectUser == null ? void 0 : projectUser.role) === "admin" && onCustomLifeThreadClicked,
      project,
      defaultThemes: themes,
      updateThemes,
      user
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "memories-new__section-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-8-tablet" }, /* @__PURE__ */ React.createElement(
    InputGroup,
    {
      description: /* @__PURE__ */ React.createElement("p", { className: "form-label__description" }, /* @__PURE__ */ React.createElement(
        Trans,
        {
          i18nKey: "form.description.memory.memorySnippet",
          values: { preferredName: project.preferred_name }
        },
        /* @__PURE__ */ React.createElement(
          "a",
          {
            className: "link",
            href: process.env.external_url["memory_highlights"],
            onClick: trackMemoryHighlightLink,
            target: "_blank"
          }
        )
      )),
      label: t("form.label.memory.memorySnippet.primary"),
      labelNote: t("form.label.memory.memorySnippet.note"),
      labelSize: "large",
      maxLength: 70,
      onChange: (value) => updateField("text", value),
      value: form.text
    }
  )))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-8-tablet" }, /* @__PURE__ */ React.createElement("div", { className: "columns memories-new__button-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-4-tablet" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "secondary",
      isDisabled: !previewAvailable(),
      onClick: handleOnClick
    },
    t("form.label.memory.previewMemory")
  ))))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-8-tablet memories-new__legal-text" }, /* @__PURE__ */ React.createElement(Trans, { i18nKey: "legal.memory" }, /* @__PURE__ */ React.createElement("a", { href: t("legal.termsOfUse"), target: "_blank" }), /* @__PURE__ */ React.createElement("a", { href: t("legal.privacyPolicy"), target: "_blank" })))), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("memories-new__memory-display-modal-wrapper", {
        "is-hidden": !isModalVisible
      })
    },
    /* @__PURE__ */ React.createElement(Modal, { isVisible: isModalVisible, noPadding: true }, /* @__PURE__ */ React.createElement(
      MemoryDisplay,
      {
        isSubmitDisabled: false,
        memory: form,
        projectUser,
        previewImages,
        onEditClick: closeModal,
        onSubmitClick: handleSubmitForm,
        user,
        isSubmittingNewMemory
      }
    ))
  ), /* @__PURE__ */ React.createElement(
    MemoriesAddLifeThreadModal,
    {
      isVisible: isAddLifeThreadModalVisible,
      lifeThreadName: newLifeThreadName,
      onAddLifeThread,
      onChangeLifeThread,
      onClose: onAddLifeThreadModalOnClose
    }
  ));
};
export default MemoriesNew;
