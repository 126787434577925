import Api from "@/api/Api";
import Icon from "@/components/icon/Icon";
import NavigationItem from "@/components/navigation/NavigationItem";
import NavigationSection from "@/components/navigation/NavigationSection";
import SharedNav from "@/components/navigation/SharedNav";
import { settingsRoutes } from "@/contexts/settings";
import Modeler from "@/utils/modeler/modeler";
import { route } from "@/utils/route_utils";
import React, { useEffect, useState } from "react";
import { generatePath, useRouteMatch } from "react-router-dom";
const SettingsNavigation = ({
  handleBack,
  projectUser
}) => {
  const { slug } = (() => {
    try {
      const { params } = useRouteMatch(["/projects/:slug", "/:slug"]);
      return params;
    } catch (e) {
      return { slug: window.location.pathname.split("/").filter((x) => x)[1] };
    }
  })();
  const [role, setRole] = useState(projectUser == null ? void 0 : projectUser.role);
  const filteredSettings = Object.entries(settingsRoutes).filter(
    ([key, _]) => role === "admin" || key === "My Settings"
  );
  useEffect(() => {
    if (role)
      return;
    const fetchProjectUser = async () => {
      try {
        const { data } = await Api.utility.get(
          route("projectUser", { project_id: slug })
        );
        setRole(new Modeler(data).build().role);
      } catch (e) {
      }
    };
    fetchProjectUser();
  }, [slug]);
  return /* @__PURE__ */ React.createElement("div", { className: "shadow-md" }, handleBack && /* @__PURE__ */ React.createElement(
    "button",
    {
      className: "bg-white font-semibold flex gap-1 items-center py-2 px-3 w-full",
      onClick: handleBack
    },
    /* @__PURE__ */ React.createElement("div", { className: "w-6" }, /* @__PURE__ */ React.createElement(Icon, { icon: "galleryArrow" })),
    /* @__PURE__ */ React.createElement("div", null, "Main Menu")
  ), filteredSettings.map(([sectionTitle, routes]) => /* @__PURE__ */ React.createElement(NavigationSection, { key: sectionTitle, title: sectionTitle }, Object.entries(routes).map(([title, { path }]) => /* @__PURE__ */ React.createElement(
    NavigationItem,
    {
      key: title,
      link: generatePath(path, { slug }),
      title
    }
  )))));
};
const ProjectNavigation = ({
  project,
  projectUser,
  onShare,
  user
}) => {
  var _a;
  const [menu, setMenu] = useState("main");
  return /* @__PURE__ */ React.createElement(
    "nav",
    {
      id: "main-menu",
      className: "bg-white divide-lifeweb-blue divide-y border-b border-t border-lifeweb-blue shadow-md text-lifeweb-blue",
      "aria-label": "Main menu"
    },
    menu === "main" && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      NavigationSection,
      {
        link: route("project", { slug: project.slug }),
        title: `${(_a = project.prefix) != null ? _a : ""} ${project.full_name}`,
        mobile: true
      },
      (projectUser == null ? void 0 : projectUser.id) && /* @__PURE__ */ React.createElement(
        NavigationItem,
        {
          onClick: (e) => {
            e.preventDefault();
            setMenu("settings");
          },
          title: "Settings",
          mobile: true,
          submenu: true
        }
      ),
      project.privacy_mode === "community" && /* @__PURE__ */ React.createElement(
        NavigationItem,
        {
          title: "Share",
          onClick: (e) => {
            e.preventDefault();
            onShare();
          }
        }
      ),
      !(projectUser == null ? void 0 : projectUser.id) && /* @__PURE__ */ React.createElement(
        NavigationItem,
        {
          link: !(user == null ? void 0 : user.id) ? route("signUpPage", {
            project_slug_to_connect: project.id
          }) : route("project", {
            slug: project.slug,
            display_stay_connected_modal: true
          }),
          title: "Stay Connected"
        }
      )
    ), /* @__PURE__ */ React.createElement(SharedNav, { project, user })),
    menu === "settings" && /* @__PURE__ */ React.createElement(
      SettingsNavigation,
      {
        handleBack: () => setMenu("main"),
        projectUser
      }
    )
  );
};
export { ProjectNavigation as default, SettingsNavigation };
