import Api from "@/api/Api";
import { route } from "@/utils/route_utils";
const facebookAuthCallback = async (response, loginPage, editPage) => {
  if (response.authResponse) {
    let facebookCallbackUrl = route("facebookAuthCallbackUrl");
    facebookCallbackUrl += `?oauth_only_login=true&login_page=${loginPage}&edit_page=${editPage}`;
    return Api.utility.get(facebookCallbackUrl);
  }
};
export default facebookAuthCallback;
