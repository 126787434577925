import Api from "@/api/Api";
import Button from "@/components/button/Button";
import ResetTokenExpModal from "@/components/modal/ResetTokenExpModal";
import NewPassword from "@/contexts/registrations/NewPassword";
import { validatePassword } from "@/utils/form_utils";
import { route } from "@/utils/route_utils";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
const PasswordSet = (props) => {
  const { t } = useTranslation();
  const { encryptedToken } = props;
  const initialUserForm = {
    password: "",
    reset_password_token: encryptedToken
  };
  const [userForm, setUserForm] = useState(initialUserForm);
  const [resetTokenExpModalVisible, setResetTokenExpModalVisible] = useState(false);
  const onUpdateUserField = (field, value) => {
    setUserForm({
      ...userForm,
      [field]: value
    });
  };
  const isSaveButtonActive = validatePassword(userForm.password);
  const saveNewPassword = async () => {
    try {
      const url = route("resetPassword");
      console.log(url);
      const payload = {
        user: {
          ...userForm
        }
      };
      const response = await Api.utility.patch(url, payload);
      window.location.href = response.data.redirect_path;
    } catch (error) {
      console.log(error.response.data.error_message);
      if (error.response && error.response.status == 404 && error.response.data.error_message === t("views.devise.modal.resetPassword.expiredLink.subTitle")) {
        setResetTokenExpModalVisible(true);
      }
    }
  };
  const onConfirm = () => {
    const url = route("newPassword");
    window.location.href = url;
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    ResetTokenExpModal,
    {
      visible: resetTokenExpModalVisible,
      onConfirm
    }
  ), /* @__PURE__ */ React.createElement(
    NewPassword,
    {
      passwordPlaceholder: t(
        "views.memories.usersNew.placeholders.createPassword"
      ),
      onUpdateUserField,
      userPassword: userForm.password
    }
  ), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-10-mobile is-5-tablet" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "primary",
      isDisabled: !isSaveButtonActive,
      onClick: saveNewPassword
    },
    t("form.actions.save")
  ))));
};
export default PasswordSet;
