import ShareModal from "@/components/modal/ShareModal";
import ActionButton from "@/components/navigation/ActionButton";
import Header, {
  HeaderActions,
  HeaderButton,
  HeaderTitle,
  HeaderTitleSection
} from "@/components/navigation/Header";
import { useDetectClickOutside } from "@/helpers/hooks/useDetectClickOutside";
import {
  useIdentityQuery,
  useProjectQuery,
  useProjectUserQuery
} from "@/queries";
import { route } from "@/utils/route_utils";
import classNames from "classnames";
import React, { useRef, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import ProjectNavigation, { SettingsNavigation } from "./ProjectNavigation";
const ProjectHeader = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const {
    params: { slug }
  } = useRouteMatch(["/projects/:slug", "/:slug"]);
  const settingsRouteMatch = useRouteMatch("/:slug/settings");
  const { data: project } = useProjectQuery(slug);
  const { data: projectUser } = useProjectUserQuery(slug);
  const { data: user, isLoading: isUserLoading } = useIdentityQuery();
  const settingsMenuRef = useRef(null);
  useDetectClickOutside(settingsMenuRef, () => setSettingsOpen(false));
  const projectNavigationRef = useRef(null);
  useDetectClickOutside(projectNavigationRef, () => setNavOpen(false));
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Header,
    {
      mobileMenu: project && projectUser && user && /* @__PURE__ */ React.createElement(
        ProjectNavigation,
        {
          onShare: () => setShareModalVisible(true),
          project,
          projectUser,
          user
        }
      )
    },
    /* @__PURE__ */ React.createElement(HeaderTitleSection, null, project && /* @__PURE__ */ React.createElement(HeaderTitle, { link: route("project", { slug: project.slug }) }, project.prefix, " ", project.full_name), /* @__PURE__ */ React.createElement(HeaderActions, null, (projectUser == null ? void 0 : projectUser.id) && /* @__PURE__ */ React.createElement("div", { ref: settingsMenuRef, id: "settings-button" }, /* @__PURE__ */ React.createElement(
      ActionButton,
      {
        icon: "gears",
        label: "Settings",
        onClick: () => setSettingsOpen((current) => !current)
      }
    ), /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classNames(
          "hidden sm:-left-24 w-[260px] sm:top-10 z-40",
          {
            "absolute sm:block": settingsOpen
          }
        ),
        onClick: () => setSettingsOpen(false)
      },
      /* @__PURE__ */ React.createElement(SettingsNavigation, null)
    )), (project == null ? void 0 : project.privacy_mode) === "community" && !settingsRouteMatch && /* @__PURE__ */ React.createElement(
      ActionButton,
      {
        icon: "upload",
        label: "Share",
        onClick: () => setShareModalVisible(true)
      }
    ), project && !(projectUser == null ? void 0 : projectUser.id) && /* @__PURE__ */ React.createElement(
      ActionButton,
      {
        icon: "heart",
        label: "Stay Connected",
        onClick: () => window.location.href = !(user == null ? void 0 : user.id) ? route("signUpPage", {
          project_slug_to_connect: project.id
        }) : route("project", {
          slug: project.slug,
          display_stay_connected_modal: true
        })
      }
    ))),
    /* @__PURE__ */ React.createElement("div", { className: "hidden sm:flex items-center justify-center gap-8 px-8" }, /* @__PURE__ */ React.createElement(
      HeaderButton,
      {
        icon: "helpFilled",
        link: "https://www.lifeweb360.com/contact-us"
      },
      "Help"
    ), (user == null ? void 0 : user.id) && /* @__PURE__ */ React.createElement("div", { ref: projectNavigationRef }, /* @__PURE__ */ React.createElement(
      HeaderButton,
      {
        icon: "profileFilled",
        onClick: () => setNavOpen((current) => !current)
      },
      "My Account"
    ), /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classNames(
          "sm:right-6 sm:max-w-[260px] w-full top-[5.5rem] z-40",
          {
            absolute: navOpen,
            hidden: !navOpen,
            "sm:hidden": !(user == null ? void 0 : user.id)
          }
        )
      },
      project && projectUser && user && /* @__PURE__ */ React.createElement(
        ProjectNavigation,
        {
          onShare: () => setShareModalVisible(true),
          project,
          projectUser,
          user
        }
      )
    )), !isUserLoading && !(user == null ? void 0 : user.id) && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(HeaderButton, { icon: "logIn", link: "/users/sign_in" }, "Log In"), /* @__PURE__ */ React.createElement(HeaderButton, { icon: "signUp", link: "/users/sign_up" }, "Sign Up")))
  ), (project == null ? void 0 : project.privacy_mode) === "community" && /* @__PURE__ */ React.createElement(
    ShareModal,
    {
      isVisible: shareModalVisible,
      onClose: () => setShareModalVisible(false),
      project
    }
  ));
};
export default ProjectHeader;
