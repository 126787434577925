import { buildPath } from "./api_utils";
const basePath = "api/v1";
const api = (path) => {
  return `${basePath}/${path}`;
};
export const full = (path) => {
  return `/${path}`;
};
export const routes = {
  // API ROUTES
  acceptInvitation: api("invitations/accept_invitation"),
  bookGoogleAuth: api("/books/authorize_google_api"),
  comment: api("comments/:id"),
  comments: api("comments"),
  existEmail: api("registrations/exist_email"),
  group: api("groups/:slug"),
  groupMemories: api("groups/:group_id/memories"),
  groupMemory: api("groups/:group_id/memories/:id"),
  groupProject: api("groups/:group_id/projects/:id"),
  groupProjects: api("groups/:group_id/projects"),
  groupReflection: api("groups/:group_id/reflections/:id"),
  groupReflections: api("groups/:group_id/reflections"),
  groupUpdates: api("groups/:group_id/updates"),
  inviteUsers: api("users/invite_user"),
  memoriesIndex: api("memories/deprecated"),
  jobShow: api("jobs/:id"),
  memory: api("memories/:id"),
  memoryBulkUpload: api("memories/bulk_upload"),
  memoryEmail: api("memories/:id/email"),
  memoryQuotes: api("projects/:project_id/memory_quotes"),
  memoryVideosThumbnail: api("memory_videos/thumbnail"),
  projectContributors: api("projects/:project_id/contributors"),
  projectMemories: api("projects/:project_id/memories"),
  projectRequestAccess: api("projects/:project_id/request_access"),
  projectThemes: api("projects/:project_id/themes"),
  projectUsersUpdate: api("project_users/:project_user_id"),
  projects: api("projects"),
  projectsEmail: api("projects/:project_id/email"),
  projectsUpdate: api("projects/:slug"),
  projectUpdate: api("project_updates/:id"),
  projectUpdates: api("projects/:project_id/project_updates"),
  projectUser: api("projects/:project_id/project_users/current"),
  projectWordCloud: api("projects/:project_id/word_clouds"),
  resendInvitation: api("invitations/resend_invitation"),
  resetPassword: api("reset_passwords"),
  search: api("search"),
  signIn: api("sessions"),
  signUp: api("registrations"),
  socialUserCreationConfirmation: api("social_user/creation_confirmation"),
  subscriptions: api("subscriptions"),
  themesIndex: api("themes"),
  theme: api("themes/:id"),
  user: api("users"),
  userShow: api("users/me"),
  // HTML ROUTES
  bookNew: ":slug/books/new",
  contributions: "projects/:slug/settings/contributions",
  editMemory: ":slug/settings/contributions/:memory_id",
  groupMemoryShow: "groups/:slug/memories/:id",
  groupProjectNew: "groups/:slug/:member_name/new",
  groupProjectReport: "groups/:slug/:member_name/report",
  groupProjectShow: "groups/:slug/:member_name/:id",
  groupProjectEdit: "groups/:slug/:member_name/:id/edit",
  groupShow: "groups/:slug",
  groupStayConnected: "groups/:slug/stay-connected",
  memoriesNew: ":slug/memories/new",
  memoryCuration: "projects/:slug/settings/memory_curation",
  memoryEdit: "memories/:id/edit",
  profile: "profile",
  project: ":slug",
  projectAccessDenied: ":slug/access_denied",
  projectNew: "create",
  projectMemory: ":slug/:memory_id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})?",
  projectCurateMemory: "projects/:slug/settings/memory_curation/curate_memory",
  projectDetails: "projects/:slug/settings/details",
  projectObituary: ":slug/obituary",
  projectSettings: "projects/:slug/settings/project_settings",
  upload: "upload",
  googleAuthCallbackOnlyLoginUrl: "users/auth/google_oauth2/callback",
  facebookAuthCallbackUrl: "users/auth/facebook/callback",
  newPassword: "users/password/new",
  signInPage: "users/sign_in",
  signUpPage: "users/sign_up",
  newUpdate: ":slug/updates/new",
  update: ":slug/updates/:id",
  updateEdit: ":slug/updates/:id/edit",
  updates: ":slug/updates"
};
export const route = (name, params) => {
  const path = routes[name];
  let fullPath = `/${path}`;
  if (params) {
    const queryParams = {};
    for (const key of Object.keys(params)) {
      const searchKey = `:${key}`;
      if (fullPath.includes(searchKey)) {
        fullPath = fullPath.replace(searchKey, params[key]);
      } else {
        queryParams[key] = params[key];
      }
    }
    fullPath = fullPath.replace(new RegExp(/\(.*\)/, "g"), "").replace(new RegExp(/\?/, "g"), "");
    return buildPath(fullPath, queryParams);
  }
  return fullPath;
};
