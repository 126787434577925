import Api from "@/api/Api";
import { route } from "@/utils/route_utils";
const googleAuthCallback = async (authResult, generatedFormAuthenticityToken, loginPage, editPage) => {
  if (authResult.code) {
    let googleCallbackUrl = route("googleAuthCallbackOnlyLoginUrl");
    googleCallbackUrl += `?oauth_only_login=true&login_page=${loginPage}&edit_page=${editPage}`;
    const body = {
      code: authResult.code,
      authenticity_token: generatedFormAuthenticityToken
    };
    return Api.utility.post(googleCallbackUrl, body);
  }
};
export default googleAuthCallback;
