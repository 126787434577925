import Api from "@/api/Api";
import ActivityIndicator from "@/components/activity/ActivityIndicator";
import Button from "@/components/button/Button";
import IconButton from "@/components/button/IconButton";
import CloseButton from "@/components/modal/CloseButton";
import Modal from "@/components/modal/Modal";
import { H2 } from "@/components/typography";
import { useProjectQuery, useProjectUserQuery } from "@/queries";
import Modeler from "@/utils/modeler/modeler";
import { route } from "@/utils/route_utils";
import { sanitizeUserInput } from "@/utils/string_utils";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
const WelcomeMessage = ({ project }) => {
  const history = useHistory();
  return /* @__PURE__ */ React.createElement("div", { className: "md:px-4" }, /* @__PURE__ */ React.createElement(H2, { className: "mt-0 text-center" }, "LifeWeb Updates"), /* @__PURE__ */ React.createElement("p", { className: "my-4" }, "Updates help you share important information with", " ", project.preferred_name, "'s community."), /* @__PURE__ */ React.createElement("ul", { className: "list-disc list-inside ml-4 my-4" }, /* @__PURE__ */ React.createElement("li", null, "Provide details about upcoming events or tributes"), /* @__PURE__ */ React.createElement("li", null, "Post links to fundraisers or charities"), /* @__PURE__ */ React.createElement("li", null, "Share thanks and other post-event resources"), /* @__PURE__ */ React.createElement("li", null, "Send encouragement for more memories prior to ordering a Memory Book")), /* @__PURE__ */ React.createElement("p", { className: "my-4" }, "Only you and other Admins of ", project.preferred_name, "'s LifeWeb can post updates. Once posted, they will be available for", " ", project.preferred_name, "'s community to see."), /* @__PURE__ */ React.createElement("div", { className: "flex justify-end" }, /* @__PURE__ */ React.createElement("div", { className: "md:w-40" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "secondary",
      fullWidth: true,
      onClick: () => history.push(route("newUpdate", { slug: project.slug }))
    },
    "Post an Update"
  ))));
};
const ProjectUpdatesList = ({
  admin,
  project,
  projectUpdates
}) => /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(H2, { className: "mt-0 text-center" }, "Updates to ", project.preferred_name, "'s Community"), projectUpdates.map((update, index) => /* @__PURE__ */ React.createElement(
  ProjectUpdatePreview,
  {
    admin,
    count: projectUpdates.length - index,
    key: update.id,
    ...update
  }
)));
const ProjectUpdatePreview = ({
  admin,
  author,
  content,
  count,
  created_at,
  id,
  title
}) => {
  const history = useHistory();
  const { slug } = useParams();
  const detailHref = route("update", { slug, id });
  return /* @__PURE__ */ React.createElement("div", { className: "my-5" }, /* @__PURE__ */ React.createElement("div", { className: "flex flex-col md:flex-row md:gap-5 text-sm text-gray-500" }, /* @__PURE__ */ React.createElement("span", null, "Update #", count), /* @__PURE__ */ React.createElement("span", null, new Date(created_at).toLocaleString(void 0, {
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    month: "long",
    timeZoneName: "short",
    year: "numeric"
  })), /* @__PURE__ */ React.createElement("span", null, "Posted by ", author)), /* @__PURE__ */ React.createElement(
    "h3",
    {
      className: "cursor-pointer font-bold mb-1 text-xl",
      onClick: () => history.push(detailHref)
    },
    title
  ), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "bg-lifeweb-yellow cursor-pointer h-26 px-3 py-2 overflow-hidden relative",
      onClick: () => history.push(detailHref)
    },
    /* @__PURE__ */ React.createElement(
      "p",
      {
        className: "prose max-w-none",
        dangerouslySetInnerHTML: { __html: sanitizeUserInput(content) }
      }
    ),
    /* @__PURE__ */ React.createElement("div", { className: "absolute bottom-0 h-10 top-auto bg-gradient-to-b from-transparent to-lifeweb-yellow w-full" })
  ), /* @__PURE__ */ React.createElement("div", { className: "flex gap-4 justify-end mt-3" }, admin && /* @__PURE__ */ React.createElement(
    "button",
    {
      className: "bg-white block border border-lifeweb-blue font-bold mb-0 px-4 py-1 rounded text-lifeweb-blue",
      onClick: () => history.push(route("updateEdit", { slug, id }))
    },
    "Edit"
  ), /* @__PURE__ */ React.createElement(
    "button",
    {
      className: "bg-white block border border-lifeweb-blue font-bold px-6 py-1 rounded text-lifeweb-blue",
      onClick: () => history.push(detailHref)
    },
    "Read more"
  )));
};
const ProjectUpdatesIndex = () => {
  const history = useHistory();
  const { slug } = useParams();
  const { data: project, isLoading: isProjectLoading } = useProjectQuery(slug);
  const { data: projectUser } = useProjectUserQuery(slug);
  const admin = (projectUser == null ? void 0 : projectUser.role) === "admin";
  const [loading, setLoading] = useState(true);
  const [projectUpdates, setProjectUpdates] = useState([]);
  const handleClose = () => history.push(route("project", { slug: project.slug }));
  useEffect(() => {
    const fetchProjectUpdates = async () => {
      const url = route("projectUpdates", { project_id: slug });
      const response = await Api.utility.get(url);
      const updates = new Modeler(response.data).build();
      if (updates.length === 0 && !admin)
        return handleClose();
      setProjectUpdates(updates);
      setLoading(false);
    };
    fetchProjectUpdates();
  }, []);
  useEffect(() => {
    if (isProjectLoading)
      return;
    mixpanel.track("updates_viewed", {
      kind: "project",
      project_id: project.id
    });
  }, [isProjectLoading]);
  return /* @__PURE__ */ React.createElement(Modal, { isVisible: true, onClose: handleClose }, /* @__PURE__ */ React.createElement("div", { className: "flex items-center justify-between mb-2" }, admin && projectUpdates.length > 0 ? /* @__PURE__ */ React.createElement(
    IconButton,
    {
      icon: "add",
      onClick: () => history.push(route("newUpdate", { slug })),
      size: "regular",
      text: "New Update"
    }
  ) : /* @__PURE__ */ React.createElement("div", null), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(CloseButton, { onClose: handleClose }))), projectUpdates.length > 0 && project && /* @__PURE__ */ React.createElement(
    ProjectUpdatesList,
    {
      admin,
      project,
      projectUpdates
    }
  ), loading && /* @__PURE__ */ React.createElement(ActivityIndicator, null), !loading && projectUpdates.length === 0 && /* @__PURE__ */ React.createElement(WelcomeMessage, { project }));
};
export default ProjectUpdatesIndex;
