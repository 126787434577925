import FilterableMultiSelect from "@/components/form/FilterableMultiSelect";
import React, { useState } from "react";
const Title = ({ text }) => /* @__PURE__ */ React.createElement("pre", { className: "mb-4 mt-12" }, /* @__PURE__ */ React.createElement("code", null, text));
const Playground = () => {
  const [
    selectedFilterableMultiSelectOptions,
    setSelectedFilterableMultiSelectOptions
  ] = useState([]);
  return /* @__PURE__ */ React.createElement("div", { className: "max-w-screen-md mx-auto" }, /* @__PURE__ */ React.createElement(Title, { text: "<FilterableMultiSelect />" }), /* @__PURE__ */ React.createElement(
    FilterableMultiSelect,
    {
      limit: 2,
      options: [
        {
          label: "Option 1",
          value: "option1"
        },
        {
          label: "Option 2",
          value: "option2"
        },
        {
          label: "Option 3",
          value: "option3"
        }
      ],
      selected: selectedFilterableMultiSelectOptions,
      setStateFunction: setSelectedFilterableMultiSelectOptions
    }
  ));
};
export { Playground };
